@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

body{
  background-color:black;
}

.out{
  animation:ou 0.5s forwards;
}
@keyframes ou{
  from{
    transform:translateX(120%);
  }
  to{
    transform:translateX(0%);
  }
}

.in{
  animation:in 0.5s forwards;
}
@keyframes in{
  from{
    transform:translateX(0%);
  }
  to{
    transform:translateX(120%);
  }
}

/*animation for projects card*/

.card1{
  animation:movef 1s forwards;
}
@keyframes movef{

  to{
    transform:translateX(0%);
  }
}

.card2{
  animation:moves 1s forwards;
}
@keyframes moves{

  to{
    transform:translateX(0%);
  }
}

.card3{
  animation:hidee 1s forwards;
}
@keyframes hidee{
  from{
    display:block;
  }
  to{
    display:none;
  }
}

.section{
  animation:exp 0.5s forwards;
}
@keyframes exp{
  to{
    width:100%;
  }
}

.no-scroll::-webkit-scrollbar{
  display:none;
}

